*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: 'Muli', sans-serif;
  background-image: linear-gradient(145deg, $black, $black_900);
  min-height: 100%;
}

h1 {
  font-size: 3rem;
  margin: 0;
  color: $yellow;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 3.6rem;
  }
}

p {
  color: $grey_300;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.1rem;

  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
}

.main-wrapper {
  padding: 4rem;

  @media screen and (min-width: 768px) {
    padding: 5rem;
  }
}
