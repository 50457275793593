@import url(https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  font-size: 62.5%;
  height: 100%; }

body {
  font-family: 'Muli', sans-serif;
  background-image: -webkit-linear-gradient(305deg, #000000, #1e272e);
  background-image: linear-gradient(145deg, #000000, #1e272e);
  min-height: 100%; }

h1 {
  font-size: 3rem;
  margin: 0;
  color: #ffe808;
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 3.6rem; } }

p {
  color: #5a6472;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.1rem; }
  @media screen and (min-width: 768px) {
    p {
      font-size: 2rem; } }

.main-wrapper {
  padding: 4rem; }
  @media screen and (min-width: 768px) {
    .main-wrapper {
      padding: 5rem; } }

